  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-28-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert sécheresse Aix en provence</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>L’expertise sécheresse indépendante à Aix-en-Provence</h1>

<p>Le cabinet RV Expertises, basé à Aix-en-Provence, intervient à la demande de l’assuré pour effectuer votre expertise sécheresse suite à un sinistre.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert sécheresse Aix en provence" />
</div>


<p>Nous prenons en charge votre dossier afin de vous préconiser les travaux de réparation adaptés à votre situation.</p>
<p>Dans le cadre d’un litige avec votre assurance, nous vous accompagnons jusqu’à la juste indemnisation de votre sinistre.</p>
<h2>L’expertise sécheresse, comment ça marche ? </h2>

<p>L’Expert sécheresse met en œuvre un travail d’analyse de l’expert concernant des fissures consécutives à un épisode de sécheresse. </p>

<p>L’expertise sécheresse a pour objectif de préconiser des travaux de reprises adaptées et pérennes et de déterminer si la sécheresse est le facteur déterminant d'apparition des fissures.</p>
<h2>L’expertise sécheresse, pourquoi ? </h2>

<p>Les conclusions d’un Expert sécheresse indépendant peuvent différer de celles de l’Expert d’assurance. </p>

<p>Lorsque vous ne contestez pas les conclusions de l’expert d’assurance, votre dossier est donc classé et vous n’êtes pas indemnisé alors que vous aviez pourtant établi un lien entre une période de sécheresse et l’apparition de fissures sur votre ouvrage.</p>

<p>Dans une telle configuration, vous avez alors la possibilité de demander la tenue d’une contre-expertise, lors de laquelle les experts exposeront leurs analyses afin de trouver une issue amiable au litige.</p>

<p>L’objectif d’une contre-expertise est une issue amiable, avec au mieux un consensus. </p>
<p>Dans le cas inverse, la tenue d’une expertise judiciaire est le dernier recours possible.</p>

<h2>Le cabinet RV Expertises, spécialiste de l’expertise sécheresse pour l’assuré</h2>
<p>La sécheresse et ses sinistres sont LA spécialité du cabinet RV Expertises</p>

<p>L’équipe RV Expertises, composée d’un Expert Construction (15 ans d’expérience bâtiment et risques naturels) et d’un sapiteur Ingénieur structure (40 ans d’expérience) mettent tout en œuvre pour vous offrir une expertise détaillée et approfondie, afin de défendre vos intérêts !</p>

<p>Les dommages consécutifs à une sécheresse sont généralement très coûteux. C’est pourquoi l’intervention d’un expert technique sécheresse peut vous permettre d’être indemnisé à la juste valeur de vos dommages, dans le cadre des garanties contractuelles qui vous sont dues.</p>

<p>N’hésitez pas à nous contacter pour plus d’informations ou une demande de devis.</p>


<p><u></u>                                                                   </p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter le cabinet RV Expertises</a>
    </div>
</div>



                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-secheresse-aix-en-provence/' className='active'>Expert sécheresse Aix en provence</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details